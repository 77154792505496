import React, { FC } from 'react';
import { CourseType } from '@/controllers/graphql/generated';
import { useShouldShowProfessions } from '@/components/hooks/useShouldShowProfessions.hook';
import { OtherProfessions } from './components/OtherProfessions';
import { OtherCourses } from './components/OtherCourses';

interface Props {
  id: string;
  types: CourseType[];
  title: string;
  exceptSlug?: string;
}

export const OtherLearningTracksBlock: FC<Props> = ({
  id,
  types,
  exceptSlug,
  title,
}) => {
  const shouldShowProfessions = useShouldShowProfessions();

  if (shouldShowProfessions) {
    return (
      <OtherProfessions
        id={id}
        title={title}
        exceptSlug={exceptSlug}
      />
    );
  }

  return (
    <OtherCourses
      id={id}
      title={title}
      types={types}
      exceptSlug={exceptSlug}
    />
  );
};
