import React, { FC, memo } from 'react';
import { ProfessionSlugs } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { PopularProfessionCardTag } from './PopularProfessionCardTag';
import { NonCodingProfessionCardTag } from './NonCodingProfessionCardTag';
import { NewProfessionCardTag } from './NewProfessionCardTag';

interface Props {
  slug: string;
}

export const ProfessionCardTag: FC<Props> = memo((props) => {
  const { slug } = props;

  switch (slug) {
    case ProfessionSlugs.Qa: {
      return <PopularProfessionCardTag />;
    }

    case ProfessionSlugs.Recruitment: {
      return <NonCodingProfessionCardTag />;
    }

    case ProfessionSlugs.DigitalMarketing: {
      return <NonCodingProfessionCardTag />;
    }

    case ProfessionSlugs.DataAnalytics: {
      return <NewProfessionCardTag />;
    }

    case ProfessionSlugs.UiUx: {
      return (
        <>
          <PopularProfessionCardTag />
          <NonCodingProfessionCardTag />
        </>
      );
    }

    default: {
      return null;
    }
  }
});
