import { useMemo } from 'react';
import * as Types from '@/controllers/graphql/generated';
import { CourseType } from '@/controllers/graphql/generated';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { ROUTES } from '@/controllers/router/router.contants';
import { useShouldShowProfessions } from '@/components/hooks/useShouldShowProfessions.hook';
import { ProfessionSlugs } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';

type Course = Pick<Types.Course, 'id' | 'slug' | 'type'>;

interface Input {
  courses: Course[];
  source: string;
  useCTXHref: boolean;
  professionSlug: string;
}

type Output = {
  postpaidCourseLink: string;
  prepaidCourseLink: string;
  postpaidCTAHref: string;
  prepaidCTAHref: string;
  singleButtonHref: string;
};

export const useCourseAndProfessionLinks = (props: Input): Output => {
  const {
    courses,
    source,
    useCTXHref,
    professionSlug,
  } = props;

  const ctaContext = useCtaContext();

  const shouldShowProfessions = useShouldShowProfessions();

  const [postpaidCourseSlug, prepaidCourseSlug] = useMemo(() => {
    const findCourseByType = (type: CourseType) => (
      courses.find((course) => course.type === type)
    );

    const postpaidCourse = findCourseByType(CourseType.Postpaid);
    const prepaidCourse = findCourseByType(CourseType.Prepaid);

    return [
      postpaidCourse?.slug || '',
      prepaidCourse?.slug || '',
    ];
  }, [courses]);

  const postpaidCourseLink = postpaidCourseSlug
    ? ROUTES.courses(postpaidCourseSlug, {
      source,
    })
    : '';

  const prepaidCourseLink = prepaidCourseSlug
    ? ROUTES.courses(prepaidCourseSlug, {
      source,
    })
    : '';

  const postpaidCTAHref = ctaContext.buildHref({
    courseSlug: postpaidCourseSlug,
    source,
  });

  // 🚨 START|Hardcoded link for UI/UX 🚨
  const isCoursesPage = ctaContext.pageType === PageType.COURSES;
  const isDesignProfession = professionSlug === ProfessionSlugs.UiUx;
  const shouldShowHardcodedLink = isCoursesPage && isDesignProfession;
  const designPageProps = shouldShowHardcodedLink
    ? {
      courseSlug: professionSlug,
      form_id_modifier: 'merged',
      postpaidCourseSlug: professionSlug,
      prepaidCourseSlug,
    }
    : {};
  // 🏁 END|Hardcoded link for UI/UX 🏁

  const prepaidCTAHref = ctaContext.buildHref({
    courseSlug: prepaidCourseSlug,
    source,
    ...designPageProps,
  });

  const professionLink = ROUTES.courses(professionSlug, {
    source,
  });

  const courseLink = shouldShowProfessions
    ? professionLink
    : prepaidCourseLink || postpaidCourseLink;

  const requestLink = prepaidCTAHref || postpaidCTAHref;

  const singleButtonHref = useCTXHref
    ? requestLink
    : courseLink;

  return {
    singleButtonHref,
    postpaidCourseLink,
    prepaidCourseLink,
    postpaidCTAHref,
    prepaidCTAHref,
  };
};
