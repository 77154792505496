import React, { FC } from 'react';
import { useOtherCoursesList } from '@/controllers/courses/courses.hooks/useOtherCoursesList';
import { CourseType } from '@/controllers/graphql/generated';
import { OtherCoursesList } from './OtherCoursesList';

interface Props {
  id?: string;
  title: string;
  exceptSlug?: string;
  types: CourseType[];
}

export const OtherCourses: FC<Props> = ({
  id,
  title,
  exceptSlug,
  types,
}) => {
  const courses = useOtherCoursesList({
    exceptSlug,
    types,
  });

  if (courses.length > 0) {
    return (
      <OtherCoursesList
        id={id}
        title={title}
        items={courses}
      />
    );
  }

  return null;
};
