import React, { FC, memo } from 'react';
import { Link } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { DurationProfessionCardTag } from '../ProfessionCardTags/DurationProfessionCardTag';
import styles from '../ProfessionCard/ProfessionCard.module.scss';
import { ProfessionCardTag } from '../ProfessionCardTags/ProfessionCardTag';

interface Props {
  href: string;
  name: string;
  courseDuration: number | null;
  slug: string;
}

export const ProfessionCardHeader: FC<Props> = memo((props) => {
  const {
    href,
    name,
    courseDuration,
    slug,
  } = props;

  return (
    <>
      <Link href={href}>
        <a className={cn(typography.landingH3, styles.title, 'mb-12')}>
          <h3>{name}</h3>
        </a>
      </Link>

      <div className={cn(styles.professionTags, 'mb-24')}>
        <ProfessionCardTag slug={slug} />

        <DurationProfessionCardTag courseDuration={courseDuration} />
      </div>
    </>
  );
});
