import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const PurpleEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="296" height="296" viewBox="0 0 296 296" fill="none">
    <g filter="url(#filter0_f_28964_49705)">
      <circle cx="148" cy="148" r="100" fill="url(#paint0_linear_28964_49705)" />
    </g>
    <defs>
      <filter id="filter0_f_28964_49705" x="-16" y="-16" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28964_49705" />
      </filter>
      <linearGradient id="paint0_linear_28964_49705" x1="23" y1="232.167" x2="261.333" y2="72.1667" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5A32F1" />
        <stop offset="1" stopColor="#5A32F1" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
