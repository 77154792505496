import React, { FC, memo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { IconFireFill } from '@/components/ui/icons/IconFireFill';
import styles from './ProfessionCardTags.module.scss';

export const PopularProfessionCardTag: FC = memo(() => {
  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <p
      className={cn(typography.landingTextMain, styles.popularTag)}
    >
      <IconFireFill className={cn(styles.baseTagIcon, 'mr-4')} />
      <span>{t(`${I18N_CODES.common}:profession_tag_popular`)}</span>
    </p>
  );
});
