/* This file is generated automatically. Do not modify it manually. */
import fallback from '@/images/icons/emoji/fire.png';
import { ImageData } from '@/components/ui/Image/typedefs';

const s3Path = 'icons/emoji/fire.png';

const imageData: ImageData = {
  /** Image path in "assets" folder on the media S3 bucket
  *   use it for "src" attribute in IconImage component
  */
  s3Path,
  /** StaticImageData
  *   use it for "fallback" attribute in IconImage component
  */
  fallback,
};

export default imageData;
