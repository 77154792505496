import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { section } from '@/components/ui/section';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { ROUTES } from '@/controllers/router/router.contants';
import { Link, useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from '../OtherLearningTracksBlock.module.scss';
import { LearningTracksShortInfo } from '../OtherLearingTracksBlock.typedefs';

interface Props {
  id?: string;
  items: LearningTracksShortInfo[];
  title: string;
}

export const OtherCoursesList: FC<Props> = ({
  id,
  items,
  title,
}) => {
  const { t } = useTranslation([I18N_CODES.profile]);

  return (
    <div
      id={id}
      className={cn({ [section.scrollSection]: Boolean(id) })}
    >
      <div className="grid-container">
        <h5 className={cn(typography.landingH5, 'mb-16')} data-qa="all-courses-title">
          {title}
        </h5>

        <div className={styles.items}>
          {items.map((item) => (
            <Link
              key={item.id}
              href={ROUTES.courses(item.slug)}
              passHref
            >
              <Button
                size={Button.size.Large}
                mode={Button.mode.Secondary}
                data-qa='course-link-button'
                text={`${t(`${I18N_CODES.profile}:course_label`)} ${item.name}`}
                className={styles.button}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
