import React, {
  FC, memo,
} from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  ProfessionsListWithCoursesFragment,
} from '@/controllers/profession/generated/professionsListWithCourses.fragment.generated';
import { CourseType } from '@/controllers/graphql/generated';
import { CourseDurationFallback } from '@/components/courseLanding/FormatComparisonSection/FormatComparisonSection.constants';
import { rawImageLoader } from '@/components/ui/Image/utils/imageLoader';
import { ProfessionCardHeader } from '../ProfessionCardHeader/ProfessionCardHeader';
import styles from '../ProfessionCard/ProfessionCard.module.scss';

interface Props {
  profession: ProfessionsListWithCoursesFragment;
  prepaidCourseLink: string;
  postpaidCourseLink: string;
  handleClick: (type: CourseType) => void;
}

export const ProfessionCardDouble: FC<Props> = memo((props) => {
  const {
    profession,
    prepaidCourseLink,
    postpaidCourseLink,
    handleClick,
  } = props;

  const {
    name,
    slug,
    description = '',
    backgroundImage,
    minimalCourseDuration = CourseDurationFallback.POSTPAID,
  } = profession;

  const { t } = useTranslation([I18N_CODES.home]);

  const backgroundImageUrl = backgroundImage?.url && rawImageLoader(
    { src: backgroundImage.url },
  );

  return (
    <div
      className={cn(
        styles.cardWrapper,
        { [styles.letterCorner]: !backgroundImage },
      )}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      data-qa="profession-card"
    >
      <ProfessionCardHeader
        name={name}
        href={prepaidCourseLink || postpaidCourseLink}
        courseDuration={minimalCourseDuration}
        slug={slug}
      />

      {description && (
        <p className={cn(typography.landingTextMain, 'mb-32')}>
          {description}
        </p>
      )}
      <div className={styles.buttons}>
        {postpaidCourseLink && (
          <Button
            mode={Button.mode.BrandSecondary}
            size={Button.size.Large}
            text={t(`${I18N_CODES.home}:postpaid_course_button`)}
            hasFullWidth
            isInternalLink
            href={postpaidCourseLink}
            data-qa="fulltime-course-more-details-button"
            onClick={() => handleClick(CourseType.Postpaid)}
          />
        )}

        <Button
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          text={t(`${I18N_CODES.home}:prepaid_course_button`)}
          hasFullWidth
          isInternalLink
          href={prepaidCourseLink}
          className={cn({
            [styles.partTimeButton]: !postpaidCourseLink,
          })}
          data-qa='fx-course-details-button'
          onClick={() => handleClick(CourseType.Prepaid)}
        />
      </div>
    </div>
  );
});
