import React, {
  FC, memo,
} from 'react';
import {
  ProfessionsListWithCoursesFragment,
} from '@/controllers/profession/generated/professionsListWithCourses.fragment.generated';
import {
  useCourseAndProfessionLinks,
} from '@/components/homepage-landing/ProfessionsListSection/ProfessionsListSection.hooks/useCourseAndProfessionLinks.hook';
import { analyticsSDK } from '@/controllers/analytics';
import { CourseType } from '@/controllers/graphql/generated';
import { ProfessionCardSingle } from '../ProfessionCardSingle/ProfessionCardSingle';
import { ProfessionCardDouble } from '../ProfessionCardDouble/ProfessionCardDouble';
import { CardMode } from './ProfessionCard.typedefs';

interface Props {
  profession: ProfessionsListWithCoursesFragment;
  cardMode?: CardMode;
  useCTXHref?: boolean;
  singleModeButtonText?: string;
}

export const ProfessionCard: FC<Props> = memo((props) => {
  const {
    profession,
    cardMode,
    useCTXHref = false,
    singleModeButtonText,
  } = props;

  const {
    courses,
  } = profession;

  const {
    prepaidCourseLink,
    postpaidCourseLink,
    singleButtonHref,
  } = useCourseAndProfessionLinks({
    courses,
    source: 'profession_card',
    useCTXHref,
    professionSlug: profession.slug,
  });

  const handleClick = (type: CourseType) => {
    const slug = profession
      .courses
      .find((course) => course.type === type)?.slug;

    if (slug) {
      analyticsSDK.landing.sendButtonClickCourseSelectionEvent({
        course: slug,
        courseType: type,
      });
    }
  };

  switch (cardMode) {
    case CardMode.SINGLE: {
      return (
        <ProfessionCardSingle
          profession={profession}
          singleModeButtonText={singleModeButtonText}
          singleButtonHref={singleButtonHref}
          prepaidCourseLink={singleButtonHref}
          handleClick={handleClick}
        />
      );
    }

    default: {
      return (
        <ProfessionCardDouble
          profession={profession}
          prepaidCourseLink={prepaidCourseLink}
          postpaidCourseLink={postpaidCourseLink}
          handleClick={handleClick}
        />
      );
    }
  }
});
