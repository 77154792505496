import { ImageData } from '@/components/ui/Image/typedefs';

export enum ProfessionSlugs {
  Qa = 'qa',
  DataAnalytics = 'data-analytics',
  Recruitment = 'recruitment',
  DigitalMarketing = 'digital-marketing',
  UiUx = 'ui-ux',
  Python = 'python',
  Java = 'java',
  Frontend = 'frontend',
  Fullstack = 'fullstack',
  DevOps = 'devops',
}

export interface BenefitCardProps {
  id: number;
  title: string;
  description: string;
  shouldShowStar: boolean;
  className?: string;
  image: ImageData;
}
