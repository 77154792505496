import React, { FC } from 'react';
import { OtherCoursesList } from './OtherCoursesList';
import { useOtherProfessions } from '../OtherLearningTracksBlock.hooks/useOtherProfessions.hook';

interface Props {
  id?: string;
  title: string;
  exceptSlug?: string;
}

export const OtherProfessions: FC<Props> = ({
  id,
  title,
  exceptSlug,
}) => {
  const professions = useOtherProfessions({ exceptSlug });

  if (professions.length > 0) {
    return (
      <OtherCoursesList
        id={id}
        title={title}
        items={professions}
      />
    );
  }

  return null;
};
