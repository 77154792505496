import React, { FC, memo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import { IconClockFilled } from '@/components/ui/icons/IconClockFilled';
import styles from './ProfessionCardTags.module.scss';

interface Props {
  courseDuration: number | null;
}

export const DurationProfessionCardTag: FC<Props> = memo((props) => {
  const { courseDuration } = props;

  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <p className={cn(typography.landingTextMain, styles.regularTag)}>
      <IconClockFilled className={cn(styles.baseTagIcon, 'mr-4')} />
      <span>
        {t(`${I18N_CODES.home}:home_course_duration`, {
          duration: courseDuration,
          count: Number(courseDuration),
        })}
      </span>
    </p>
  );
});
