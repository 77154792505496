import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconUserSmileFill: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.99967 14.6666C4.31767 14.6666 1.33301 11.6819 1.33301 7.99992C1.33301 4.31792 4.31767 1.33325 7.99967 1.33325C11.6817 1.33325 14.6663 4.31792 14.6663 7.99992C14.6663 11.6819 11.6817 14.6666 7.99967 14.6666ZM4.66634 8.66658C4.66634 9.55064 5.01753 10.3985 5.64265 11.0236C6.26777 11.6487 7.11562 11.9999 7.99967 11.9999C8.88373 11.9999 9.73158 11.6487 10.3567 11.0236C10.9818 10.3985 11.333 9.55064 11.333 8.66658H9.99967C9.99967 9.19702 9.78896 9.70573 9.41389 10.0808C9.03882 10.4559 8.53011 10.6666 7.99967 10.6666C7.46924 10.6666 6.96053 10.4559 6.58546 10.0808C6.21039 9.70573 5.99967 9.19702 5.99967 8.66658H4.66634ZM5.33301 7.33325C5.59822 7.33325 5.85258 7.22789 6.04011 7.04036C6.22765 6.85282 6.33301 6.59847 6.33301 6.33325C6.33301 6.06804 6.22765 5.81368 6.04011 5.62614C5.85258 5.43861 5.59822 5.33325 5.33301 5.33325C5.06779 5.33325 4.81344 5.43861 4.6259 5.62614C4.43836 5.81368 4.33301 6.06804 4.33301 6.33325C4.33301 6.59847 4.43836 6.85282 4.6259 7.04036C4.81344 7.22789 5.06779 7.33325 5.33301 7.33325ZM10.6663 7.33325C10.9316 7.33325 11.1859 7.22789 11.3734 7.04036C11.561 6.85282 11.6663 6.59847 11.6663 6.33325C11.6663 6.06804 11.561 5.81368 11.3734 5.62614C11.1859 5.43861 10.9316 5.33325 10.6663 5.33325C10.4011 5.33325 10.1468 5.43861 9.95923 5.62614C9.7717 5.81368 9.66634 6.06804 9.66634 6.33325C9.66634 6.59847 9.7717 6.85282 9.95923 7.04036C10.1468 7.22789 10.4011 7.33325 10.6663 7.33325Z" fill="currentColor" />
  </BaseIcon>
);
