import { LearningTracksShortInfo } from '../OtherLearingTracksBlock.typedefs';
import { useGetOtherProfessionsList } from './useGetOtherProfessionList.hook';

interface UseOtherProfessions {
  (
    options: {
      exceptSlug?: string;
      skip?: boolean;
    }
  ): LearningTracksShortInfo[];
}

export const useOtherProfessions: UseOtherProfessions = (options) => {
  const {
    exceptSlug,
    skip,
  } = options;

  const [professions] = useGetOtherProfessionsList({ skip });

  if (exceptSlug) {
    return professions.filter((profession) => (
      profession.slug !== exceptSlug
    ));
  }

  return professions;
};
