import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconClockFilled: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path xmlns="http://www.w3.org/2000/svg" d="M12.5527 22.3101C7.02973 22.3101 2.55273 17.8331 2.55273 12.3101C2.55273 6.78706 7.02973 2.31006 12.5527 2.31006C18.0757 2.31006 22.5527 6.78706 22.5527 12.3101C22.5527 17.8331 18.0757 22.3101 12.5527 22.3101ZM13.5527 12.3101V7.31006H11.5527V14.3101H17.5527V12.3101H13.5527Z" fill="currentColor" />
  </BaseIcon>
);
