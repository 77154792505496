import React, { FC, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement>;

export const OrangeEllipse: FC<Props> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="296" height="296" viewBox="0 0 296 296" fill="none">
    <g filter="url(#filter0_f_28957_32901)">
      <circle cx="148" cy="148" r="100" fill="url(#paint0_linear_28957_32901)" />
    </g>
    <defs>
      <filter id="filter0_f_28957_32901" x="-16" y="-16" width="328" height="328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_28957_32901" />
      </filter>
      <linearGradient id="paint0_linear_28957_32901" x1="190.857" y1="375.679" x2="62" y2="38" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7246" />
        <stop offset="1" stopColor="#FF7246" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
