import { useMemo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { CourseType } from '@/controllers/graphql/generated';
import { excludeEntitiesFromList } from '@/controllers/courses/courses.utils/excludeEntitiesFromList';
import { GetOtherProfessionsListQueryResult, useGetOtherProfessionsListQuery } from '../graphql/generated/otherProfessionsListquery.generated';
import { OtherProfessionsListFragment } from '../graphql/generated/otherProfessionList.fragment.generated';

type Output = [
  OtherProfessionsListFragment[],
  GetOtherProfessionsListQueryResult,
];

type HookArgs = {
  skip?: boolean;
};

export const useGetOtherProfessionsList = (args: HookArgs): Output => {
  const { subDomain, language } = useSubDomainContext();

  const { skip } = args;

  const queryResult = useGetOtherProfessionsListQuery({
    variables: {
      domain: subDomain,
      lang: language,
      courseTypes: [CourseType.Postpaid, CourseType.Prepaid],
    },
    skip,
  });

  const content = useMemo(
    () => {
      const result = queryResult.data?.professionsListWithCourses ?? [];

      return excludeEntitiesFromList(result);
    },
    [queryResult.data?.professionsListWithCourses],
  );

  return [content, queryResult];
};
