import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconFireFill: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.99957 15.3333C6.99768 15.3332 6.01891 15.0322 5.19018 14.4691C4.36145 13.9061 3.72098 13.1071 3.35181 12.1757C2.98263 11.2443 2.90179 10.2235 3.11976 9.24555C3.33773 8.26765 3.84446 7.37779 4.57424 6.69133C5.46891 5.84933 7.66624 4.33333 7.33291 1C11.3329 3.66667 13.3329 6.33333 9.33291 10.3333C9.99957 10.3333 10.9996 10.3333 12.6662 8.68667C12.8462 9.202 12.9996 9.756 12.9996 10.3333C12.9996 11.6594 12.4728 12.9312 11.5351 13.8689C10.5974 14.8065 9.32566 15.3333 7.99957 15.3333Z" fill="currentColor" />
  </BaseIcon>
);
